
import { mapStores } from "pinia"
import { useCameraStore } from "@/stores/camera"
import {
  AnalyticsEvent,
  AnalyticsEventPageId,
} from "@evercam/shared/types/analytics"
/*global google*/
import CameraMapInfoWindow from "@/components/map/CameraMapInfoWindow"

export default {
  name: "GoogleMap",
  meta: {
    pageId: AnalyticsEventPageId.accountMapView,
  },
  components: {
    CameraMapInfoWindow,
  },
  data() {
    return {
      center: {
        lat: 52.51195,
        lng: 6.089625,
      },
      map: null,
      camera: {},
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      showCameraMapInfoTooltip: false,
      currentMidx: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
    }
  },
  head() {
    return {
      title: "Evercam | Map View",
      meta: [
        { charset: "utf-8" },

        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useCameraStore),
  },
  watch: {
    showCameraMapInfoTooltip(visible) {
      this.$analytics.saveEvent(
        AnalyticsEvent.mapViewToggleCameraMapInfoTooltip,
        {
          visible,
        }
      )
    },
  },
  mounted() {
    this.$refs.gmap?.$mapPromise?.then((map) => {
      const bounds = new google.maps.LatLngBounds()
      for (let m of this.cameraStore.cameras) {
        if (m.location) {
          bounds.extend(m.location)
        }
      }
      map.fitBounds(bounds)
    })
    this.$analytics.saveEvent(AnalyticsEvent.pageView)
  },
  methods: {
    toggleInfoWindow(camera, idx) {
      this.infoWindowPos = camera.location
      this.camera = camera

      if (this.currentMidx === idx) {
        this.showCameraMapInfoTooltip = !this.showCameraMapInfoTooltip
      } else {
        this.showCameraMapInfoTooltip = true
        this.currentMidx = idx
      }
    },
    setMarkarImage(marker) {
      if (marker.isOnline) {
        return "/online-marker.png"
      } else if (marker.status === "waiting") {
        return "/waiting-for-activation.png"
      } else {
        return "/offline-marker.png"
      }
    },
    onLinkClick() {
      this.$analytics.saveEvent(AnalyticsEvent.mapViewClickCameraLink)
    },
  },
}
